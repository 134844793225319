import React from 'react';
import { useNavigate } from 'react-router-dom';

const Login: React.FC = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const navigate = useNavigate();


    const Elements = () => {
      console.log("working");
      navigate("/layout/dashboard");
    }
    
  return (
    <div>
      <p>Login page</p>
      <button onClick={Elements}>Go to dashboard</button>
    </div>
  )
}

export default Login;
