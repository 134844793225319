import React from 'react'

const Dashboard : React.FC = () => { 
  return (
    <div>
      welcome dashboard
    </div>
  )
}

export default Dashboard
