import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { AuthProvider } from "./context/AuthContext";
import PrivateRoute from "./context/PrivateRoute";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./components/auth/login";
import Elements from "./components/auth/elements";
import Elements1 from "./components/auth/Elements1";
import Layout from "./layout";
import Dashboard from "./components/pages/dashboard";
import DesignStudio from "./components/pages/DesignStudio";

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/layout" element={<Layout />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="design-studio" element={<DesignStudio />} />
            <Route path="elements" element={<Elements />} />
            <Route path="elements1" element={<Elements1 />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
