import React, { useEffect, useState, useRef } from 'react';
import './DesignStudio.css';
import { Button, Col, Form, Row, Table, Pagination, InputGroup, FormControl } from 'react-bootstrap';
import Select from 'react-select';
import CreateIcon from '../../assets/design-studio/create-new.svg';
import OrderPattern from "../../assets/icons/order-pattern-icon.svg";
import FilterIcon from "../../assets/design-studio/filter-table.svg";
import NoDataIcon from "../../assets/design-studio/no-design.svg";
import UploadPattern from "../../assets/design-studio/upload-pattern.svg";
import UploadIcon from "../../assets/design-studio/upload-icon.svg";
import CloseModal from "../../assets/design-studio/close-modal.svg";
import SaveBtn from "../../assets/design-studio/save-btn.svg";
import Duplicate from "../../assets/design-studio/duplicate.svg";
import MinusAdjustment from "../../assets/design-studio/max-adjustment.svg";
import CustomSize from "../../assets/icons/custom-size.svg";
import ToggleOpen from "../../assets/design-studio/toggle-eye-open.svg";
import AddAdjustment from "../../assets/design-studio/min-adjustment.svg";
import FrontBodyDiagram from "../../assets/design-studio/front-body.svg";
import BackBodyDiagram from "../../assets/design-studio/back-body.svg";
import AddIcon from "../../assets/icons/add-icon.svg";
import BlouseIcon from "../../assets/icons/blouse-icon.svg";
import NormalSleeve from "../../assets/icons/normal-sleeve.svg";
import WomanIcon from "../../assets/design-studio/woman-icon.svg";
import VNeckIcon from "../../assets/icons/v-neck.svg";

interface SaveAndContinueProps {
  step: number;
  onNext: () => void;
  onPrev: () => void;
}

interface CustomDesignProps {
  step: number;
}

const DesignStudio = () => {
  const designsData = [
    {
      id: 'SEE1234568',
      name: 'Single Breasted Slim Fit Formal Blazer',
      pattern: 'Icon',
      category: 'Men',
      subCategory: 'Formals',
      subSubCategory: 'Slim Fit Shirt',
      status: 'Saved',
    },
    {
      id: 'SEE1234569',
      name: 'Single Breasted Slim Fit Formal Blazer',
      pattern: 'Icon',
      category: 'Men',
      subCategory: 'Formals',
      subSubCategory: 'Slim Fit Shirt',
      status: 'Published',
    },
    {
      id: 'SEE1234570',
      name: 'Single Breasted Slim Fit Formal Blazer',
      pattern: 'Icon',
      category: 'Men',
      subCategory: 'Formals',
      subSubCategory: 'Slim Fit Shirt',
      status: 'Published',
    },
    {
      id: 'SEE1234571',
      name: 'Single Breasted Slim Fit Formal Blazer',
      pattern: 'Icon',
      category: 'Men',
      subCategory: 'Formals',
      subSubCategory: 'Slim Fit Shirt',
      status: 'Published',
    },
    {
      id: 'SEE1234572',
      name: 'Single Breasted Slim Fit Formal Blazer',
      pattern: 'Icon',
      category: 'Men',
      subCategory: 'Formals',
      subSubCategory: 'Slim Fit Shirt',
      status: 'Published',
    },
    {
      id: 'SEE1234573',
      name: 'Single Breasted Slim Fit Formal Blazer',
      pattern: 'Icon',
      category: 'Men',
      subCategory: 'Formals',
      subSubCategory: 'Slim Fit Shirt',
      status: 'Published',
    },
  ];

  const [selectedDesigns, setSelectedDesigns] = useState<string[]>([]);
  const [isAnySelected, setIsAnySelected] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredDesigns, setFilteredDesigns] = useState(designsData);
  const [currentPage, setCurrentPage] = useState(1);
  const [designsPerPage, setDesignsPerPage] = useState(10);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setIsAnySelected(selectedDesigns.length > 0);
  }, [selectedDesigns]);

  const indexOfLastDesign = currentPage * designsPerPage;
  const indexOfFirstDesign = indexOfLastDesign - designsPerPage;
  const currentDesigns = filteredDesigns.slice(indexOfFirstDesign, indexOfLastDesign);

  const handleSelectDesign = (designId: any) => {
    setSelectedDesigns((prevSelected: any) =>
      prevSelected.includes(designId)
        ? prevSelected.filter((id: any) => id !== designId)
        : [...prevSelected, designId]
    );
  };

  const handleSelectAll = (e: any) => {
    if (e.target.checked) {
      setSelectedDesigns(designsData.map((design) => design.id));
    } else {
      setSelectedDesigns([]);
    }
  };

  const handleDelete = () => {
    alert('Deleting selected designs...');
  };

  const handleDownload = () => {
    alert('Downloading selected designs...');
  };

  const handlePageChange = (pageNumber: number) => setCurrentPage(pageNumber);

  const handleDesignsPerPageChange = (e: any) => setDesignsPerPage(Number(e.target.value));

  const toggleModal = () => setShowModal(!showModal);
  useEffect(() => {
    console.log(showModal);

  }, [showModal])


  if (filteredDesigns.length === 0) {
    return (
      <div className="studio-container no-designs">
        <div className="studio-header">
          <span className="studio-title">All Designs (0)</span>
        </div>
        <div className="empty-state">
          <img src={NoDataIcon} alt="No Designs" />
          <p>It seems there are no designs created yet!</p>
          <Button className="create-button" variant="primary">
            <img src={CreateIcon} alt="create-icon" /> Create New
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="studio-container">
      <div className="studio-header">
        <span className="studio-title">All Designs ({designsData.length})</span>
        <Button className="create-button" variant="primary" onClick={toggleModal}>
          <img src={CreateIcon} alt="create-icon" /> Create New
        </Button>
        {showModal && <CreateDesignModal onClose={toggleModal} />}
      </div>
      <div className='header-divider'></div>
      <div className="element-wrapper">
        <Row className="mb-3">
          <Col md={6}>
            {/* <div style={{display: 'flex', alignItems: 'center', borderRight: '1px solid #B1B2B7'}}>
              Show
              <Form.Select onChange={handleDesignsPerPageChange} value={designsPerPage} className='pagination'>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
              </Form.Select>
              per page
            </div>
            <div>1 to 30</div> */}
          </Col>
          <Col md={6} className='action-left'>
            <InputGroup>
              <FormControl
                placeholder="Search designs"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </InputGroup>

           {/* // <img src={FilterIcon} alt="filter-icon" />// */}

          </Col>
        </Row>
        <Table responsive className="order-status-table">
          <thead>
            <tr>
              <th style={{ textAlign: 'center' }}>
                <Form.Check
                  type="checkbox"
                  onChange={handleSelectAll}
                  checked={selectedDesigns.length === designsData.length}
                />
              </th>
              <th>Design Name</th>
              <th>Design ID</th>
              <th>Pattern</th>
              <th>Category</th>
              <th>Sub Category</th>
              <th>Sub Category</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {isAnySelected && (
              <tr>
                <td colSpan={8} style={{ padding: '0px', textAlign: 'center' }}>
                  <Row style={{ background: '#FFEB3433', padding: '5px 14px' }}>
                    <Col style={{ display: 'flex', alignItems: 'center' }}>
                      <Form.Check
                        type="checkbox"
                        checked={selectedDesigns.length > 0}
                      />
                      <div style={{ marginLeft: '2.5%', fontSize: '14px', fontWeight: '500', color: '#0072B9' }}>
                        {selectedDesigns.length} Selected Designs
                      </div>
                      <div
                        style={{ backgroundColor: '#fff', padding: '3px', marginLeft: '2.5%', width: '80px', height: '28px', borderRadius: '4px', textAlign: 'center' }}
                        onClick={handleDelete}
                      >
                        Delete
                      </div>
                      <div
                        style={{ backgroundColor: '#fff', padding: '3px', marginLeft: '2.5%', width: '80px', height: '28px', borderRadius: '4px', textAlign: 'center' }}
                        onClick={handleDownload}
                      >
                        Download
                      </div>
                    </Col>
                  </Row>
                </td>
              </tr>
            )}
            {designsData.map((design) => (
              <tr key={design.id}>
                <td style={{ textAlign: 'center' }}>
                  <Form.Check
                    type="checkbox"
                    onChange={() => handleSelectDesign(design.id)}
                    checked={selectedDesigns.includes(design.id)}
                  />
                </td>
                <td>{design.name}</td>
                <td>{design.id}</td>
                <td>
                  <img
                    src={OrderPattern}
                    alt="pattern"
                    style={{ width: '30px', height: '30px', position: 'inherit' }}
                  />
                </td>
                <td>{design.category}</td>
                <td>{design.subCategory}</td>
                <td>{design.subSubCategory}</td>
                <td>
                  <span
                    style={{
                      backgroundColor:
                        design.status === 'Saved' ? '#15C5732E' : '#0072B92E',
                      color: design.status === 'Saved' ? '#15C573' : '#0072B9',
                      padding: '5px 15px',
                      borderRadius: '30px',
                      fontSize: '14px',
                      fontWeight: '500'
                    }}
                  >
                    {design.status}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  )
}

const CreateDesignModal = ({ onClose }: { onClose: () => void }) => {
  const [step, setStep] = useState(1);
  const modalBodyRef = useRef<HTMLDivElement>(null);

  const handleNext = () => {
    setStep(prevStep => {
      const newStep = prevStep + 1;
      if (modalBodyRef.current) {
        modalBodyRef.current.scrollTop = 0; 
      }
      return newStep;
    });
  };

  const handlePrev = () => setStep(step - 1);

  useEffect(() => {
    console.log("step", step);
  }, [step])

  return (
    <div className="modal-overlay">
      <img className='close-modal' src={CloseModal} onClick={onClose} alt="close-icon" />
      <div className="modal-content">
        <div className="modal-header">
          <div>
            Create Design
          </div>
          <div className="modal-footer">
            <Button className='save-btn' style={{ marginRight: '3%' }}>Save</Button>
            <Button className='save-ctn-btn' disabled={step < 5} onClick={onClose}>Save & Publish</Button>
          </div>
        </div>
        <div style={{ marginLeft: '0px', marginTop: '15px' }} className='header-divider'></div>
        <div className="modal-body">
          <Row style={{marginRight: '1%'}}>
            <Col md="3">
              <div className="procontainer">
                <div className="prolabels">
                  <span className="prolabel">Select Category</span>
                </div>
                <div className="progress-container">
                  <div className="progress">
                    <div
                      className="progress-bar"
                      style={{ width: " 25%" }}
                    ></div>
                  </div>
                </div>
              </div>
            </Col>
            <Col md="3">
              <div className="procontainer">
                <div className="prolabels">
                  <span className="prolabel">Item Information</span>
                </div>
                <div className="progress-container">
                  <div className="progress">
                    <div
                      className="progress-bar"
                      style={{ width: " 25%" }}
                    ></div>
                  </div>
                </div>
              </div>
            </Col>
            <Col md="3">
              <div className="procontainer">
                <div className="prolabels">
                  <span className="prolabel">Custom Component</span>
                </div>
                <div className="progress-container">
                  <div className="progress">
                    <div
                      className="progress-bar"
                      style={{ width: " 25%" }}
                    ></div>
                  </div>
                </div>
              </div>
            </Col>
            <Col md="3">
              <div className="procontainer">
                <div className="prolabels">
                  <span className="prolabel">Custom Design</span>
                </div>
                <div className="progress-container">
                  <div className="progress">
                    <div
                      className="progress-bar"
                      style={{ width: " 25%" }}
                    ></div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <div className="modal-form">
            {step === 1 && <SelectCategory />}
            {step === 2 && <ItemInformation />}
            {step === 3 && <CustomComponent />}
            {step === 4 && <CustomDesign />}
            {step === 5 && <PostCustomDesign />}
          </div>
        </div>
        <SaveAndContinue
          step={step}
          onNext={handleNext}
          onPrev={handlePrev}
        />
      </div>
    </div>
  );
};

const SaveAndContinue: React.FC<SaveAndContinueProps> = ({ step, onNext, onPrev }) => {
  return (
    <div className='design-button'>
      <>
        {step > 1 && (
          <Button variant="outline-primary" style={{ marginRight: '2%' }} onClick={onPrev}>Back</Button>
        )}
        {step < 5 && (
          <Button variant="outline-primary" onClick={onNext}>Save & Continue <img src={SaveBtn} alt="btn" /></Button>
        )}
      </>
    </div>
  );
}

const SelectCategory = () => {
  const [designName, setDesignName] = useState('');
  const [childCategory, setChildCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [parentCategory, setParentCategory] = useState('');
  const [patternFiles, setPatternFiles] = useState<string[]>([]);
  const [iconFiles, setIconFiles] = useState<string[]>([]);

  const handleFileUpload = (event: any) => {
    console.log(event);
    const file = event?.target?.files[0];
    if (file) {
      setPatternFiles([...patternFiles, URL.createObjectURL(file)]);
    }
  };

  const handleIconUpload = (event: any) => {
    console.log(event);

    const file = event?.target?.files[0];
    if (file) {
      setIconFiles([...iconFiles, URL.createObjectURL(file)]);
    }
  };

  return (
    <>
      <div className="design-form">
        <div className="form-section">
          <label className='form-label'>
            Design Name*
            <Form.Control type="text" value={designName} className='input'
              onChange={(e) => setDesignName(e.target.value)} />
          </label>

          <div className="design-icon">
            <label className='form-label' style={{ marginBottom: '0px' }}>Design Icon</label>
            <div className="icon-upload">
              <div className='upload-before'>
                <img src={UploadIcon} alt="icon" />
                Click to upload
              </div>
              {/* <div>
                <img src="design-icon-url" alt="Design Icon" />
                <button>Edit</button>
              </div> */}
            </div>
          </div>

          <label className='form-label' style={{ marginBottom: '20px' }}>
            Select Child Category*
            <Form.Select aria-label="Default select example" value={childCategory} onChange={(e) => setChildCategory(e.target.value)}>
              <option></option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </Form.Select>
          </label>

          <label className='form-label' style={{ marginBottom: '20px' }}>
            Select Sub Category*
            <Form.Select aria-label="Default select example" value={subCategory} onChange={(e) => setSubCategory(e.target.value)}>
              <option></option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </Form.Select>
          </label>

          <label className='form-label' style={{ marginBottom: '0px' }}>
            Select Parent Category*
            <Form.Select aria-label="Default select example" value={parentCategory} onChange={(e) => setParentCategory(e.target.value)}>
              <option></option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </Form.Select>
          </label>
        </div>

        <div className="pattern-files">
          <h4>Pattern File(s)</h4>
          <div className="pattern-upload">
            <div className='pattern-side'>FRONT</div>
            <img src={UploadPattern} alt="pattern" />
            <div className='pattern-browse'>Drag your file or browse</div>
            <div className='pattern-format'>Supported Format : SVG (512 x 512)</div>
          </div>
          <div className="pattern-upload" style={{ marginTop: '6%' }}>
            <div className='pattern-side'>BACK</div>
            <img src={UploadPattern} alt="pattern" />
            <div className='pattern-browse'>Drag your file or browse</div>
            <div className='pattern-format'>Supported Format : SVG (512 x 512)</div>
          </div>
        </div>
      </div>
    </>
  );
};

const ItemInformation = () => {
  const [items, setItems] = useState([
    { name: '', standardSize: '', minAdjustment: '', maxAdjustment: '', toggleOpen: '0' },
  ]);
  const [sleeveItems, setSleeveItems] = useState([
    { item: '', standard: '', min: '', max: '', toggleOpen: '0' },
  ]);
  const [activeButton, setActiveButton] = useState('uk');
  const [activeSizeButton, setActiveSizeButton] = useState('in');
  const [selected, setSelected] = useState('Front');
  const [sleeveName, setSleeveName] = useState('');

  const handleSelect = (option: string) => {
    setSelected(option);
  };

  const addMoreItems = () => {
    setItems([
      ...items,
      { name: '', standardSize: '', minAdjustment: '', maxAdjustment: '', toggleOpen: '0' },
    ]);
  };

  const addMoreSleeveItems = () => {
    setSleeveItems([
      ...sleeveItems,
      { item: '', standard: '', min: '', max: '', toggleOpen: '0' },
    ]);
  };

  const handleInputChange = (index: number, event: React.ChangeEvent<any>) => {
    const { name, value } = event.target as HTMLInputElement;
    const updatedItems = [...items];
    updatedItems[index][name as keyof typeof updatedItems[0]] = value;
    setItems(updatedItems);
  };

  const handleSleeveInputChange = (index: number, event: React.ChangeEvent<any>) => {
    const { name, value } = event.target as HTMLInputElement;
    console.log(event)
    const updatedItems = [...sleeveItems];
    updatedItems[index][name as keyof typeof updatedItems[0]] = value;
    setSleeveItems(updatedItems);
  };

  useEffect(() => {
    console.log(sleeveItems)
  }, [sleeveItems])

  const handleActiveButton = (buttonId: string) => {
    setActiveButton(buttonId);
  };

  const handleActiveSizeButton = (buttonId: string) => {
    setActiveSizeButton(buttonId);
  };

  const toggleImage = (index: number) => {
    const updatedItems = [...items];
    updatedItems[index].toggleOpen = updatedItems[index].toggleOpen === '0' ? '1' : '0';
    setItems(updatedItems);
  };

  const toggleSleeveImage = (index: number) => {
    const updatedItems = [...sleeveItems];
    updatedItems[index].toggleOpen = updatedItems[index].toggleOpen === '0' ? '1' : '0';
    setSleeveItems(updatedItems);
  };

  return (
    <div className="size-form-container">
      <Form style={{ border: '1px solid #48496433', padding: '30px', borderRadius: '6px' }}>
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <label className="form-label" style={{ marginBottom: '20px', width: '40%' }}>
            Choose Size
            <Form.Select aria-label="Default select example">
              <option></option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </Form.Select>
          </label>
          <div className="size-options">
            <div className="button-group">
              <div
                className={`button ${selected === 'Front' ? 'active' : ''}`}
                onClick={() => handleSelect('Front')}
              >
                Front
              </div>
              <div
                className={`button ${selected === 'Back' ? 'active' : ''}`}
                onClick={() => handleSelect('Back')}
              >
                Back
              </div>
              <div
                className={`button ${selected === 'Sleeve' ? 'active' : ''}`}
                onClick={() => handleSelect('Sleeve')}
              >
                Sleeve
              </div>
            </div>
            <div className="toggle-buttons">
              <div
                className={`toggle-button ${activeSizeButton === 'in' ? 'active' : ''}`}
                onClick={() => handleActiveSizeButton('in')}
              >
                in
              </div>
              <div
                className={`toggle-button ${activeSizeButton === 'cm' ? 'active' : ''}`}
                onClick={() => handleActiveSizeButton('cm')}
              >
                cm
              </div>
            </div>
            <div className="toggle-buttons">
              <div
                className={`toggle-button ${activeButton === 'uk' ? 'active' : ''}`}
                onClick={() => handleActiveButton('uk')}
              >
                UK
              </div>
              <div
                className={`toggle-button ${activeButton === 'us' ? 'active' : ''}`}
                onClick={() => handleActiveButton('us')}
                style={{ border: '1px solid #0072B91A' }}
              >
                US
              </div>
              <div
                className={`toggle-button ${activeButton === 'eu' ? 'active' : ''}`}
                onClick={() => handleActiveButton('eu')}
              >
                EU
              </div>
            </div>
          </div>
        </div>

        <div className="header-divider" style={{ margin: '10px 0px 20px' }}></div>

        <Row>
          <Col sm={5}>
            <label className="form-label" style={{ width: '100%' }}>
              Name of the Items
            </label>
          </Col>
          <Col sm={1} style={{ width: '13%' }}>
            <label className="form-label" style={{ width: '100%' }}>
              Standard Size
            </label>
          </Col>
          <Col sm={3}>
            <label className="form-label" style={{ width: '100%' }}>
              Adjustment (Min/Max)
            </label>
          </Col>
          <Col sm={2}></Col>
        </Row>

        {items.map((item, index) => (
          <Row key={index} className="mb-3">
            <Col sm={5}>
              <div style={{ position: 'relative' }}>
                <Form.Control
                  type="text"
                  name="name"
                  value={item.name}
                  className="input eye-focus"
                  onChange={(e) => handleInputChange(index, e)}
                />
                {item.name && (
                  <img style={{
                    position: 'absolute',
                    top: '55%',
                    right: '20px',
                    transform: 'translateY(-50%)',
                    cursor: 'pointer',
                  }} src={item.toggleOpen === '0' ? CustomSize : ToggleOpen} alt="custom-size" onClick={() => toggleImage(index)} />
                )}
              </div>
            </Col>
            <Col sm={1} style={{ width: '12%' }}>
              <Form.Control
                type="text"
                name="standardSize"
                value={item.standardSize}
                className="input"
                onChange={(e) => handleInputChange(index, e)}
              />
            </Col>
            <Col sm={3}>
              <InputGroup>
                <div style={{ display: 'flex' }}>
                  <Form.Control
                    type="text"
                    name="minAdjustment"
                    value={item.minAdjustment}
                    className="input"
                    style={{ width: '100px' }}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                  <Form.Control
                    type="text"
                    name="maxAdjustment"
                    value={item.maxAdjustment}
                    className="input"
                    style={{ width: '100px' }}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                </div>
              </InputGroup>
            </Col>
            <Col sm={2} className="min-max">
              <div style={{ display: 'flex' }}>
                <img style={{ marginRight: '40%', width: '28px' }} src={MinusAdjustment} alt="icon" />
                <img style={{ width: '28px' }} src={AddAdjustment} alt="icon" />
              </div>
            </Col>
            {item.toggleOpen === '1' && (<Row>
              <Col sm={5} style={{ width: '42.6%' }}>
                <div className="image-toggle-container">
                  <img src={selected === 'Front' ? FrontBodyDiagram : BackBodyDiagram} alt="Toggled Open" />
                </div>
              </Col>
            </Row>
            )}
          </Row>
        ))}

        <div className="form-actions">
          <div className="add-more-btn" onClick={addMoreItems}>
            + Add more
          </div>
          {selected !== 'Sleeve' && (
            <div className="ml-2 custom-btn">
              + Custom Components
            </div>
          )}
        </div>
        {selected === 'Sleeve' && (
          <>
            <div>
              <div className='sleeve-form-container'>
                <Row>
                  <Col sm={4}>
                    <label className="form-label" style={{ width: '100%' }}>
                      Name of the Component
                      <Form.Control
                        type="text"
                        name="name"
                        value={sleeveName}
                        className="input eye-focus"
                        onChange={(e) => setSleeveName(e.target.value)}
                      />
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col sm={5}>
                    <label className="form-label" style={{ width: '100%' }}>
                      Name of the Items
                    </label>
                  </Col>
                  <Col sm={1} style={{ width: '13%' }}>
                    <label className="form-label" style={{ width: '100%' }}>
                      Standard Size
                    </label>
                  </Col>
                  <Col sm={3}>
                    <label className="form-label" style={{ width: '100%' }}>
                      Adjustment (Min/Max)
                    </label>
                  </Col>
                  <Col sm={2}></Col>
                </Row>
                {sleeveItems.map((item, index) => (
                  <Row key={index} className="mb-3">
                    <Col sm={5}>
                      <div style={{ position: 'relative' }}>
                        <Form.Control
                          type="text"
                          name="item"
                          value={item.item}
                          className="input eye-focus"
                          onChange={(e) => handleSleeveInputChange(index, e)}
                        />
                        {item.item && (
                          <img style={{
                            position: 'absolute',
                            top: '55%',
                            right: '20px',
                            transform: 'translateY(-50%)',
                            cursor: 'pointer',
                          }} src={item.toggleOpen === '0' ? CustomSize : ToggleOpen} alt="custom-size" onClick={() => toggleSleeveImage(index)} />
                        )}
                      </div>
                    </Col>
                    <Col sm={1} style={{ width: '13%' }}>
                      <Form.Control
                        type="text"
                        name="standard"
                        value={item.standard}
                        className="input"
                        onChange={(e) => handleSleeveInputChange(index, e)}
                      />
                    </Col>
                    <Col sm={3}>
                      <InputGroup>
                        <div style={{ display: 'flex' }}>
                          <Form.Control
                            type="text"
                            name="min"
                            value={item.min}
                            className="input"
                            style={{ width: '100px' }}
                            onChange={(e) => handleSleeveInputChange(index, e)}
                          />
                          <Form.Control
                            type="text"
                            name="max"
                            value={item.max}
                            className="input"
                            style={{ width: '100px' }}
                            onChange={(e) => handleSleeveInputChange(index, e)}
                          />
                        </div>
                      </InputGroup>
                    </Col>
                    <Col sm={2} className="min-max">
                      <div style={{ display: 'flex' }}>
                        <img style={{ marginRight: '40%', width: '28px' }} src={MinusAdjustment} alt="icon" />
                        <img style={{ width: '28px' }} src={AddAdjustment} alt="icon" />
                      </div>
                    </Col>
                    {item.toggleOpen === '1' && (<Row>
                      <Col sm={5} style={{ width: '42.6%' }}>
                        <div className="image-toggle-container">
                          <img src={FrontBodyDiagram} alt="Toggled Open" />
                        </div>
                      </Col>
                    </Row>
                    )}
                  </Row>
                ))}
                <Row>
                  <Col md={1}>
                    <div className="add-btn d-flex align-items-center justify-content-center">
                      <img style={{ width: '12px', height: '12px', cursor: 'pointer' }} onClick={addMoreSleeveItems} src={AddIcon} alt="add-icon" />
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="form-bottom-actions mt-3" style={{ justifyContent: 'flex-end' }}>
                <Button variant="primary" className="mr-2">
                  + Add Component
                </Button>
                <Button variant="primary" className="duplicate-btn">
                  <img src={Duplicate} alt="icon" />Duplicate
                </Button>
              </div>
            </div>
          </>
        )}
      </Form>
      <div className="form-bottom-actions mt-3">
        <Button variant="primary" className="mr-2">
          + Add Size
        </Button>
        <Button variant="primary" className="duplicate-btn">
          <img src={Duplicate} alt="icon" />Duplicate
        </Button>
      </div>
    </div>
  );
};

const CustomComponent = () => {
  const options = [
    { value: 'Cotton', label: 'Cotton' },
    { value: 'Raymond', label: 'Raymond' },
    { value: 'Linen', label: 'Linen' },
    { value: 'Silk', label: 'Silk' },
    { value: 'Muslin', label: 'Muslin' }
  ]

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      minHeight: '50px',
      height: 'auto',
      padding: '10px 0px',
    }),
    multiValue: (provided: any) => ({
      ...provided,
      backgroundColor: '#0072B933',
      borderRadius: '30px',
    }),
    multiValueLabel: (provided: any) => ({
      ...provided,
      color: '#0072B9',
      fontSize: '16px',
      fontWeight: '500',
    }),
    multiValueRemove: (provided: any) => ({
      ...provided,
      backgroundColor: '#0072B9',
      borderRadius: '50%',
      width: '14px',
      height: '14px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '2px',
      cursor: 'pointer',
      margin: '0px 8px 0px 8px',
      ':before': {
        content: '""',
        width: '8px',
        height: '1.5px',
        backgroundColor: 'white',
        transform: 'rotate(45deg)',
        position: 'absolute',
      },
      ':after': {
        content: '""',
        width: '8px',
        height: '1.5px',
        backgroundColor: 'white',
        transform: 'rotate(-45deg)',
        position: 'absolute',
      },
      ':hover': {
        backgroundColor: '#0072B9',
      },
    }),
  };

  return (
    <div className='custom-component-container'>
      <Row>
        <Col md={3}>
          <Form.Group controlId="componentsName">
            <Form.Label style={{ marginBottom: '6%' }}>Name of the Components</Form.Label>
            <Form.Control
              type="text"
              placeholder="eg: Material"
            />
          </Form.Group>
          <div className="more-btn d-flex align-items-center justify-content-center">
            <img style={{ marginRight: '10px', width: '12px', height: '12px' }} src={AddIcon} alt="add-icon" /> Add more
          </div>
        </Col>
        <Col md={5}>
          <Form.Group controlId="preferredMaterials" className="preferred-materials float-end">
            <Form.Label>Preferred Materials</Form.Label>
            <Select options={options} isMulti placeholder="eg: Raymond, Cotton" styles={customStyles} />
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
}

const PostCustomDesign = () => {
  const labels = [
    { value: 'Lapel', label: 'Lapel' },
    { value: 'Notch', label: 'Notch' },
  ]
  const customLabelStyles = {
    control: (provided: any) => ({
      ...provided,
      minHeight: '50px',
      height: 'auto',
    }),
    multiValue: (provided: any) => ({
      ...provided,
      backgroundColor: '#0072B933',
      borderRadius: '30px',
    }),
    multiValueLabel: (provided: any) => ({
      ...provided,
      color: '#0072B9',
      fontSize: '16px',
      fontWeight: '500',
    }),
    multiValueRemove: (provided: any) => ({
      ...provided,
      backgroundColor: '#0072B9',
      borderRadius: '50%',
      width: '14px',
      height: '14px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '2px',
      cursor: 'pointer',
      margin: '0px 8px 0px 8px',
      ':before': {
        content: '""',
        width: '8px',
        height: '1.5px',
        backgroundColor: 'white',
        transform: 'rotate(45deg)',
        position: 'absolute',
      },
      ':after': {
        content: '""',
        width: '8px',
        height: '1.5px',
        backgroundColor: 'white',
        transform: 'rotate(-45deg)',
        position: 'absolute',
      },
      ':hover': {
        backgroundColor: '#0072B9',
      },
    }),
  };
  return (
    <div className='custom-component-container'>
      <Row>
        <Col md={3}>
          <Form.Group controlId="componentsName">
            <Form.Label>Name of the Components</Form.Label>
            <Form.Control
              type="text"
              placeholder="eg: neck model"
            />
          </Form.Group>
        </Col>
        <Col md={2}>
          <Form.Group controlId="tagsName">
            <Form.Label>Name of the Tags</Form.Label>
            <Select options={labels} isMulti placeholder="eg: collar" styles={customLabelStyles} />
          </Form.Group>
        </Col>
        <Col md={2} className="d-flex align-items-end">
          <Button className="upload-btn">
            <img src={UploadIcon} alt="upload-model" /> Upload File
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md={2}>
          <div className="more-btn d-flex align-items-center justify-content-center">
            <img style={{ marginRight: '10px', width: '12px', height: '12px' }} src={AddIcon} alt="add-icon" /> Add more
          </div>
        </Col>
      </Row>
    </div>
  )
}

const CustomDesign = () => {
  return (
    <>
      <div className="aari-designs">
        <div className="super-card">
          <img src={WomanIcon} alt="Women Icon" />
          <p>Women</p>
        </div>
        <div className="parent-card">
          <img src={BlouseIcon} alt="Blouse" />
          <p>Blouse</p>
        </div>
        <div className="sub-card">
          <img src={BlouseIcon} alt="3 Dart Blouse" />
          <p>3 Dart Blouse</p>
        </div>
        <div className="child-card">
          <img src={NormalSleeve} alt="Normal Sleeve" />
          <p>Normal Sleeve</p>
        </div>
        <button
          className="neck-button"
        >
          <img src={VNeckIcon} alt='icon' className="neck-icon" />
          <span>Scoop</span>
        </button>
      </div>
      <div className='upload-title'>Upload Your Choice</div>
      <div className="image-wrapper">
        <div className="pattern-upload" style={{ width: '285px', padding: '6px 0px' }}>
          <div className='pattern-side'>FRONT</div>
          <img src={UploadPattern} alt="pattern" />
          <div className='pattern-browse'>Drag your file or browse</div>
          <div className='pattern-format'>Supported Format : SVG (512 x 512)</div>
        </div>
        <div className="pattern-upload" style={{ width: '285px', padding: '6px 0px' }}>
          <div className='pattern-side'>FRONT</div>
          <img src={UploadPattern} alt="pattern" />
          <div className='pattern-browse'>Drag your file or browse</div>
          <div className='pattern-format'>Supported Format : SVG (512 x 512)</div>
        </div>
        <div className="pattern-upload" style={{ width: '285px', padding: '6px 0px' }}>
          <div className='pattern-side'>FRONT</div>
          <img src={UploadPattern} alt="pattern" />
          <div className='pattern-browse'>Drag your file or browse</div>
          <div className='pattern-format'>Supported Format : SVG (512 x 512)</div>
        </div>
        <div className="pattern-upload" style={{ width: '285px', padding: '6px 0px' }}  >
          <div className='pattern-side'>FRONT</div>
          <img src={UploadPattern} alt="pattern" />
          <div className='pattern-browse'>Drag your file or browse</div>
          <div className='pattern-format'>Supported Format : SVG (512 x 512)</div>
        </div>
      </div>
    </>
  )
}

export default DesignStudio;