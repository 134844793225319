import React from "react";
import "./element.css";
import { CardBody, Col, Row } from "react-bootstrap";

const Elements = () => {
  return (
    <div>
      <div className="main">
        <div className="container">
          <CardBody className="">
            <div className="loginform">
              <Row>
                <Col lg="4" md="4">
                  <h2>Inputs</h2>
                  <label className="label">Project Name </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter project Name"
                    name="prjname"
                    minLength={5}
                    maxLength={50}
                    required
                  />
                </Col>
                <Col lg="4" md="4">
                  <label className="label">Buttons </label>
                  <button        className="form-control btn btn-primary subbutton"  disabled>Submit</button>
                </Col>
              </Row>
            </div>
          </CardBody>
        </div>
      </div>
    </div>
  );
};

export default Elements;
